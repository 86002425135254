body {
    margin: 0;
    background-color: #ffffff;
    background-image: linear-gradient(171deg, #ffffff 0%, #eaf2ff 32%, #8ebec9 65%, #45859c 82%, #5a4b7b 100%);
}

.colors {
    color: #EBF4F9;
    color: #D6E8F2;
    color: #B2D4E7;
    color: #8EBDD9;
    color: #67A6C9;
    color: #4392BB;
}

p {
    line-height: 1.3;
    letter-spacing: 0.5px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 300;
}

h1 {
    font-size: 25px;
    font-weight: 400;
}

h2 {
    font-size: 20px;
    font-weight: 400;
    color: white;
    font-family: 'Roboto', sans-serif;
    opacity: 0.8;
    font-weight: 300;
}

  @media (max-width: 700px) {
      h1 {
          font-size: 25px;
      }

      p {
          font-size: 16px;

      }
  }

  @media (max-width: 450px) {
      h1 {
          font-size: 35px;
      }

      p {
          font-size: 16px;
      }
  }

  